import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { normalizePost } from '../services/post-utils';
import uri from 'urijs';
import { getCategoryIds } from '../selectors/categories-selectors';
import { encodeURISlug } from '../services/slug';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

export function fetchPost(postSlug) {
  return (dispatch, getState, { request }) => {
    // postSlug = postSlug.replace('post/', ''); // TODO fix santa
    postSlug = encodeURISlug(postSlug);
    const requestUrl = uri(`/_api/posts/${postSlug}`).query({ fieldsets: 'categories,owner,likes,content,subscriptions' });

    // const promise = request.metered('_api/posts/:postId')(requestUrl); // TODO: metered request
    const promise = request(requestUrl);
    let normalizedPost;

    dispatch(fetchPostRequest({ postSlug }));

    return promise
      .then(post => {
        normalizedPost = normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) });
        return dispatch(
          fetchPostSuccess({
            post: normalizedPost,
            postSlug,
          }),
        );
      })
      .catch(({ status }) => dispatch(fetchPostFailure({ postSlug, error: { status } })))
      .then(() => promise.then(() => normalizedPost));
  };
}
