import { isEmpty } from 'lodash';
import postService from '../../common/services/post';
import helpers from '../../common/services/helpers';

import { itemSelectors } from '../constants/post-list-widget';
import { EMPTY_STATE } from '../../common/constants/state-box-states';
import {
  getPostListSize,
  getCategory,
  getFeaturedPostsEnabled,
  getTitleCharCount,
  getPostOrderType,
} from '../selectors/post-list-widget-selectors';

export const createPostListController = ({ $w, appParams, wixCodeApi, config }) => ({
  pageReady: () => {
    $w(itemSelectors.POST_LIST).onItemReady(($item, itemData) => {
      $item(itemSelectors.POST_IMAGE).src = itemData.coverImage;
      $item(itemSelectors.POST_IMAGE).onClick(() => wixCodeApi.location.to(itemData.postPageUrl));
      $item(itemSelectors.POST_TITLE).text = helpers.applyCharLimitEllipsis(itemData.title, getTitleCharCount(config));
      $item(itemSelectors.POST_TITLE).onClick(() => wixCodeApi.location.to(itemData.postPageUrl));
      $item(itemSelectors.POST_DATE).text = helpers.getDateString(itemData.lastPublishedDate);
    });

    const category = getCategory(config);
    const order = helpers.getPostOrderTypeQuery(getPostOrderType(config));

    const params = {
      limit: getPostListSize(config),
      featured: getFeaturedPostsEnabled(config),
      ...(category && { category }),
      ...(order && { order }),
    };

    return postService
      .getPosts({ instance: appParams.instance, params, wixCodeApi })
      .then(posts => {
        if (isEmpty(posts)) {
          $w(itemSelectors.STATE_BOX).changeState(EMPTY_STATE);
        } else {
          $w(itemSelectors.POST_LIST).data = posts;
        }
      })
      .then(() => {
        $w(itemSelectors.MEDIA_BOX).show();
      })
      .catch(console.error);
  },
});
