import { EXPERIMENT_USE_CUSTOM_ROUTER } from '@wix/communities-blog-experiments';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import wixBlogs from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { getBlogFeedSectionUrl, getBlogPostPageSectionUrl } from '../../services/get-section-url';

const { WP_BLOGS, SITE_URLS, POST_PAGE_URLS } = wixBlogs;

export const FETCH_TOPOLOGY_SUCCESS = 'topology/FETCH_SUCCESS';

export const fetchTopologySuccess = createAction(FETCH_TOPOLOGY_SUCCESS);

export const fetchTopology = instanceId => (dispatch, getState, { wixCodeApi }) => {
  if (WP_BLOGS.includes(instanceId)) {
    return dispatch(
      fetchTopologySuccess({
        baseUrl: SITE_URLS[instanceId],
        sectionUrl: SITE_URLS[instanceId],
        postPageSectionUrl: POST_PAGE_URLS[instanceId],
        postPagePath: '/',
      }),
    );
  }

  const useBaseUrlAsSection = isExperimentEnabled(getState(), EXPERIMENT_USE_CUSTOM_ROUTER);
  const baseUrl = wixCodeApi.location.baseUrl;
  const sectionUrl = useBaseUrlAsSection ? baseUrl : getBlogFeedSectionUrl(wixCodeApi);
  const postPageSectionUrl = useBaseUrlAsSection ? baseUrl : getBlogPostPageSectionUrl(wixCodeApi);
  const postPagePath = useBaseUrlAsSection ? '/' : postPageSectionUrl.replace(baseUrl, '');

  dispatch(
    fetchTopologySuccess({
      baseUrl,
      sectionUrl,
      postPageSectionUrl,
      postPagePath,
    }),
  );
};
