import URI from 'urijs';
import { setRouterMatch } from '../router';
import { parseInstance } from '../store/instance-values/parse-instance';
import { addErrorState, addDebugState } from '../store/debug-state/debug-state-actions';
import createRequest from '../services/create-request';
import { validateLanguage } from '../services/validate-language';

const REGEX_POST_PAGE_PREVIEW = /[0-9a-fA-F]+\/preview\/([^/]+)\/?/;

export const getPreviewInstance = location => {
  const match = location.path.join('/').match(REGEX_POST_PAGE_PREVIEW);
  return match ? match[1] : undefined;
};

export const getInstance = wixCodeApi => () =>
  getPreviewInstance(wixCodeApi.location) || wixCodeApi.user.currentUser.instance;

export const resolveUser = wixCodeApi => {
  const user = wixCodeApi.user.currentUser;
  const previewInstance = getPreviewInstance(wixCodeApi.location);
  if (previewInstance) {
    user.loggedIn = true;
    user.instance = previewInstance;
    user.id = parseInstance(previewInstance).uid;
  }
  return user;
};

const noop = () => {};

export const createLogger = (isDebug, isProduction) => (!isProduction || isDebug ? console.log : noop);

export const getEnvironment = wixCodeApi => ({
  isEditor: wixCodeApi.window.viewMode === 'Editor',
  isPreview: wixCodeApi.window.viewMode === 'Preview',
  isSSR: wixCodeApi.window.rendering.env === 'backend',
  isDebug: wixCodeApi.location.query.debug || wixCodeApi.location.query.ssrDebug,
});

export const doRouting = ({ router, pathname, store, isInitialLoad = true }) =>
  router.match(pathname).then(match => isInitialLoad && store.dispatch(setRouterMatch(match)));

export function initializeNavigationHandlerForEditor({ store, router }) {
  if (typeof window !== 'undefined') {
    window.__navigateWithinBlogInternally = pathname => {
      doRouting({
        store,
        router,
        pathname,
        isInitialLoad: false,
      });
    };
  }
}

export const createRequestWithBaseUrl = ({ wixCodeApi, getStore }) => baseUrl => {
  const { isDebug } = getEnvironment(wixCodeApi);
  return createRequest({
    baseUrl,
    getInstance: getInstance(wixCodeApi),
    locale: validateLanguage(wixCodeApi.site.language),
    trackError: error => getStore().dispatch(addErrorState(error)),
    logResponse: isDebug ? response => getStore().dispatch(addDebugState(response)) : undefined,
    petriOvr: wixCodeApi.location.query.petri_ovr,
    siteRevision: wixCodeApi.site.revision,
    isDebug,
  });
};

export const appendOriginInBackend = ({ wixCodeApi, baseUrl }) => {
  const origin = URI(wixCodeApi.location.baseUrl).origin();
  const isBackend = wixCodeApi.window.rendering.env === 'backend';
  return isBackend ? `${origin}${baseUrl}` : baseUrl;
};
