import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createRequestWithBaseUrl, appendOriginInBackend } from '../../common/controller/helpers';
import commonReducers from '../../common/reducers';

export function createReduxStore({ appParams, wixCodeApi, compId, fedopsLogger, isEditor, isPreview }) {
  const p = {};
  const createRequest = createRequestWithBaseUrl({ wixCodeApi, getStore: () => p.store });
  const { apiBaseUrlClient, apiPlatformizedBaseUrlClient, apiExperimentsBaseUrlClient } = appParams.baseUrls;

  const platformizedRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiPlatformizedBaseUrlClient });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const experimentsRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiExperimentsBaseUrlClient });
  const experimentsRequest = createRequest(experimentsRequestUrl);

  const blogApiBaseUrl = isEditor || isPreview ? apiBaseUrlClient : `${wixCodeApi.location.baseUrl}${apiBaseUrlClient}`;
  const request = createRequest(blogApiBaseUrl);

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      experimentsRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
  ];

  const reducers = combineReducers({ ...commonReducers });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
