import { getPost } from '../../selectors/post-selectors';
import { isPublished } from '../event-helpers';
import { INCREMENT_POST_LIKE_COUNT_REQUEST } from '../../store/post-likes/post-likes-actions';

const handler = (action, state) => {
  const post = getPost(state, action.payload._id);
  return {
    evid: 209,
    flag: Number(post.isLiked),
    is_published: isPublished(post),
    post_id: post._id,
    eventMeta: {
      description: 'like click',
    },
  };
};

export const uouIncrementPostLikeCountEvent = { [INCREMENT_POST_LIKE_COUNT_REQUEST]: handler };
