import { getTopology } from '../store/topology/topology-selectors';

export const navigateWithinBlog = path => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateWithinPostPage = path => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};
